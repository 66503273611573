import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from '../../UIComponents/Grid/Grid'
import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'

import RadioForm from 'src/UIComponents/Radio/RadioForm.jsx'
import { darkGray } from '../../Styles/settings/Constants'

const SelectBankAccount = ({ option, onChange, checked }) => {
  const isIndividual =
    option && option.taxpayer_id && option.taxpayer_id.length === 11
  return (
    <SelectBankAccountContainer>
      <Card withShadow>
        <Grid noPadding>
          {option.resource === 'bank_account' && (
            <Grid.Row auto bigMarginBottom>
              <Grid.Col cols={2}>
                <RadioFieldContainer onChange={onChange}>
                  <RadioForm
                    checked={checked}
                    id={option.id}
                    onChange={onChange}
                  />
                </RadioFieldContainer>
              </Grid.Col>
              <Grid.Col cols={7}>
                <Grid.Row auto smallMarginBottom>
                  <Label color={darkGray} textAlign='left'>
                    <strong>{isIndividual ? 'CPF' : 'CNPJ'} </strong>
                    {option.taxpayer_id?.toDocument()}
                  </Label>
                </Grid.Row>
                <Grid.Row auto>
                  <Label color={darkGray} textAlign='left'>
                    <strong>Titular </strong>
                    {option.holder_name}
                  </Label>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col cols={7}>
                <Grid.Row smallMarginBottom>
                  <Grid.Col cols={16}>
                    <Label color={darkGray} textAlign='left'>
                      {option.bank_name}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row smallMarginBottom>
                  <Grid.Col cols={6}>
                    <Label color={darkGray} textAlign='left'>
                      <strong>Ag </strong>
                      {option.routing_number}
                    </Label>
                  </Grid.Col>
                  <Grid.Col cols={10}>
                    <Label color={darkGray} textAlign='left'>
                      <strong>Conta </strong> {option.account_number}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>
      </Card>
    </SelectBankAccountContainer>
  )
}

const SelectBankAccountContainer = styled.div`
  margin-bottom: 10px;
`

const RadioFieldContainer = styled.div`
  display: flex;
  align-items: center;
`

SelectBankAccount.propTypes = {
  option: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}
export default SelectBankAccount
