import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy } from 'lodash'

import {
  deleteFavoriteMarketplace,
  setFavoriteMarketplace,
  getFavoritesMarketplaces
} from '../../Actions/favorites'
import {
  changeMarketplaceContext
} from '../../Actions/context'
import { getMarketplaceTree } from 'src/Actions/marketplace'

import SelectMarketplaceUI from 'src/UIComponents/SelectMarketplace/SelectMarketplace'

const SelectMarketplace = (props) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const {
    context,
    rootMarketplace,
    currentMarketplace,
    favoriteMarketplaces
  } = useSelector(({ marketplace, context, favorites }) => ({
    context,
    favoriteMarketplaces: favorites.favoriteMarketplaces,
    rootMarketplace: {
      id: marketplace.rootDetails.id,
      name: marketplace.rootDetails.name,
      ein: marketplace.rootDetails.customer.ein,
      affiliationTree: marketplace.affiliationTree
    },
    currentMarketplace: {
      id: marketplace.details.id,
      name: marketplace.details.name,
      ein: marketplace.details.customer.ein
    }
  }))

  useEffect(() => {
    dispatch(getMarketplaceTree())
    dispatch(getFavoritesMarketplaces())
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
    }
  }, [isOpen])

  const handleAddFavorite = (marketplace) => {
    dispatch(setFavoriteMarketplace(marketplace))
  }

  const handleDeleteFavorite = (marketplace) => {
    dispatch(deleteFavoriteMarketplace(marketplace))
  }

  const handleSelect = (e, item) => {
    e.preventDefault()

    dispatch(changeMarketplaceContext(item))
  }

  const handleOnChangeSearch = (value) => {
    setSearch(value)
  }

  const getMarketplaces = () => {
    let marketplaces = rootMarketplace.affiliationTree.map(marketplace => ({
      ...marketplace,
      isFavorite: favoriteMarketplaces.includes(marketplace.id)
    }))

    marketplaces = orderBy(marketplaces, ['isFavorite', 'name'], ['desc', 'asc'])

    const searchCleaned = search.trim().toLowerCase().replace(/[.,\-,/]/g, '')

    marketplaces = marketplaces.filter(marketplace => {
      if (marketplace && marketplace.name) return marketplace.name.toLowerCase().includes(searchCleaned)
    })

    return marketplaces
  }

  return (
    <SelectMarketplaceUI
      {...props}
      items={getMarketplaces()}
      currentMarketplace={currentMarketplace}
      enableSelect={!!rootMarketplace.affiliationTree.length}
      handleSelect={handleSelect}
      handleOnChangeSearch={handleOnChangeSearch}
      context={context}
      setFavorite={handleAddFavorite}
      deleteFavorite={handleDeleteFavorite}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  )
}

export { SelectMarketplace }
