import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { resetReceipt } from 'src/Actions/receipt'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import SaleDetail from './SaleDetail'

const SaleDetailComponent = () => {
  const { seller } = useSelector(({ context }) => context)
  const history = useHistory()

  const matchFromBalancesList = useRouteMatch({
    path: '*/extrato/venda/:id'
  })

  const matchFromTransferDetail = useRouteMatch({
    path: '*/venda/:id'
  })

  const matchFromTransationList = useRouteMatch({
    path: '*/vendas/detalhe/:id'
  })

  const matchFromFutureTransfer = useRouteMatch({
    path: '*/lancamentos-futuros/venda/:id'
  })

  const match = matchFromTransferDetail || matchFromTransationList || matchFromBalancesList || matchFromFutureTransfer

  const [showSidebar, setShowSidebar] = useState(!!match)
  const [transactionId, setTransactionId] = useState()

  const shouldShowBackButton = !matchFromBalancesList && matchFromTransferDetail && !matchFromFutureTransfer
  const dispatch = useDispatch()

  useEffect(() => {
    if (match) {
      if (match.params['id'] !== transactionId) {
        setTransactionId(match.params['id'])
        setTimeout(() => {
          setShowSidebar(true)
        }, 500)
      }
    } else {
      setShowSidebar(false)
      setTimeout(() => {
        setTransactionId(null)
      }, 500)
    }
  }, [match])

  useEffect(() => {
    return () => dispatch(resetReceipt())
  }, [])

  const handleClose = () => {
    setShowSidebar(false)
    setTimeout(() => {
      setTransactionId(null)
      const urlSplitted = match.url.split('/')
      if (matchFromTransationList) {
        history.push(`${match.params[0]}/vendas`)
      } else if (seller?.id) {
        history.push(`/${urlSplitted[1]}/${urlSplitted[2]}/${urlSplitted[3]}`)
      } else {
        history.push(`/${urlSplitted[1]}`)
      }
    }, 500)

    dispatch(resetReceipt())
  }

  const handleBack = () => {
    history.push(`${match.params[0]}`)
  }

  return (
    <>
      {
        match && transactionId && <Sidebar show={showSidebar}>
          <SaleDetail handleGoBack={shouldShowBackButton && handleBack} handleClose={handleClose} transactionId={transactionId} />
        </Sidebar>
      }
    </>
  )
}

export default SaleDetailComponent
