import React, { Fragment, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Dots from 'src/UIComponents/Dots/Dots'
import UIReceipt from 'src/UIComponents/SaleDetail/Receipt/Receipt'
import SendReceipt from 'src/Components/SendReceiptComponent/SendReceipt'
import { getReceipt } from 'src/Actions/receipt'
import { darkerGray } from 'src/Styles/settings/Constants'

const Receipt = ({ receiptId, sale, enabledSendReceipt }) => {
  const dispatch = useDispatch()

  const {
    receiptRequesting,
    receipt,
    logo
  } = useSelector(({ receipt, custom }) => ({
    receiptRequesting: receipt.receiptRequesting,
    receiptError: receipt.receiptError,
    receipt: receipt.receipt,
    sendReceiptEmail: receipt.sendReceiptEmail,
    sendReceiptError: receipt.sendReceiptError,
    logo: custom.monochromeLogo || custom.logo
  }))

  useEffect(() => {
    if (!receipt) {
      dispatch(getReceipt(receiptId))
    }
  }, [])

  return receiptRequesting ? (
    <DotsContainer>
      <Dots color={darkerGray} outline />
    </DotsContainer>
  ) : (
    <Fragment>
      <UIReceipt
        receipt={receipt}
        sale={sale}
        logo={logo}
      />
      {enabledSendReceipt && <SendReceipt receiptId={receipt && receipt.id} />}
      <br />
      <br />
    </Fragment>
  )
}

export default Receipt

Receipt.propTypes = {
  receiptId: PropTypes.string,
  sale: PropTypes.object,
  enabledSendReceipt: PropTypes.bool
}

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
`
