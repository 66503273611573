import React from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import FormZ from 'src/UIComponents/FormZ/FormZ'
import PostalCodeFactory from '../FormFactories/PostalCodeFactory'
import formValidators from 'src/Utils/formValidators'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import { getAddressFromPostalCode } from 'src/Utils/Utils'
import SellerAddressTemplate from '../../UIComponents/TemplatesForm/SellerAddressTemplate'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import NumberFactory from '../FormFactories/NumberFactory'
import SelectFactory from '../FormFactories/SelectFactory'
import { StateTypes } from '../../Constants/StateTypes'

export const struct = t.struct({
  postalCode: formValidators.CEP,
  line1: formValidators.String,
  line2: formValidators.String,
  line3: t.maybe(t.String),
  neighborhood: formValidators.String,
  city: formValidators.String,
  state: t.Object,
  countryCode: formValidators.String
})

const SellerAddress = ({
  values,
  onChange,
  showValidation,
  editMode
}) => {
  const handleOnChange = (e) => {
    onChange({
      address: {
        ...e
      }
    })
  }

  const getAddress = async () => {
    const address = await getAddressFromPostalCode(values.postalCode)
    handleOnChange({
      ...values,
      line1: address.logradouro,
      neighborhood: address.bairro,
      city: address.localidade,
      state: {
        label: address.uf,
        value: address.uf
      }
    })
  }

  const formOptions = {
    values: values,
    options: {
      template: (locals) => SellerAddressTemplate(locals),
      fields: {
        postalCode: {
          label: 'CEP',
          factory: PostalCodeFactory,
          autocomplete: false,
          config: {
            id: 'postal-code',
            disabled: !editMode,
            onBlur: () => getAddress()
          }
        },
        line1: {
          label: 'Endereço',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'address',
            disabled: !editMode
          }
        },
        line2: {
          label: 'Número',
          factory: NumberFactory,
          autocomplete: false,
          config: {
            id: 'number',
            disabled: !editMode
          }
        },
        line3: {
          label: 'Complemento (Opcional)',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'add-info',
            disabled: !editMode
          }
        },
        neighborhood: {
          label: 'Bairro',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'neighborhood',
            disabled: !editMode
          }
        },
        city: {
          label: 'Cidade',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'city',
            disabled: !editMode
          }
        },
        state: {
          label: 'Estado',
          factory: SelectFactory,
          autocomplete: false,
          config: {
            id: 'state',
            viewMode: !editMode,
            disabled: !editMode,
            options: StateTypes,
            emptyMessage: 'Você esqueceu de selecionar aqui?'
          }
        }
      }
    }
  }

  return (
    <FormZ
      options={showValidation
        ? getFormOptionsWithValidation(formOptions.options, formOptions.values, struct)
        : formOptions.options}
      struct={struct}
      values={formOptions.values}
      onChange={handleOnChange}
      refs='addressForm'
      disabledForm={!editMode}
    />
  )
}

SellerAddress.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  editMode: PropTypes.bool
}

export { SellerAddress }
