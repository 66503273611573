import React, { useState } from 'react'
import PropTypes from 'prop-types'

import SelectMultiplyWithGroup from '../../UIComponents/SelectMultiplyWithGroup/SelectMultiplyWithGroup'

const SelectMultiplyWithGroupComponent = ({ values, hasError, errorMessage, onSelect, listItems, label, placeholder, placeholderSearch, notFoundLabel }) => {
  let arrayOptions = values
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [categoriesState, setCategoriesState] = useState({})
  const [allSelected, setAllSelected] = useState(false)

  const handleSelectAll = (e, itemsFiltered) => {
    e.stopPropagation()
    const allOptions = itemsFiltered.map(category => category.options.map(option => option.value)).flat()
    const allOptionsSelected = allOptions.filter(option => arrayOptions.includes(option)).length === allOptions.length

    setAllSelected(!allOptionsSelected)
    onSelect(allOptionsSelected ? [] : allOptions)
  }

  const handleCategorySelect = (e, itemsFiltered, allCategoryOptionsSelected, options) => {
    e.stopPropagation()

    if (allCategoryOptionsSelected) {
      options.forEach(option => {
        const { value } = option
        if (arrayOptions.includes(value)) {
          arrayOptions.splice(arrayOptions.indexOf(value), 1)
          setAllSelected(false)
        }
      })
    } else {
      options.forEach(option => {
        const { value } = option
        !arrayOptions.includes(value) && arrayOptions.push(value)

        const allOptions = itemsFiltered.map(category => category.options.map(option => option.value)).flat()
        const allOptionsSelected = arrayOptions.length === allOptions.length

        setAllSelected(allOptionsSelected)
      })
    }

    onSelect(arrayOptions)
  }

  const handleOptionSelect = (e, itemsFiltered, optionValue) => {
    e.stopPropagation()

    if (arrayOptions.includes(optionValue)) {
      arrayOptions.splice(arrayOptions.indexOf(optionValue), 1)
      setAllSelected(false)
    } else {
      arrayOptions.push(optionValue)

      const allOptions = itemsFiltered.map(category => category.options.map(option => option.value)).flat()
      const allOptionsSelected = arrayOptions.length === allOptions.length

      setAllSelected(allOptionsSelected)
    }

    onSelect(arrayOptions)
  }

  const getFilteredOptions = () => {
    if (!search) return listItems

    return listItems.reduce((acc, currentCategory) => {
      const { options, value, label } = currentCategory
      const matchCategory = label.toLowerCase().includes(search.toLowerCase())
      const matchOptions = options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))

      if (matchCategory) {
        return [
          ...acc,
          currentCategory
        ]
      } else if (matchOptions && matchOptions.length > 0) {
        return [
          ...acc,
          {
            value,
            label,
            options: matchOptions
          }
        ]
      } else return acc
    }, [])
  }

  const handleCategoryClick = categoryLabel => {
    setCategoriesState({ ...categoriesState, [categoryLabel]: !categoriesState[categoryLabel] })
  }

  const itemsFiltered = getFilteredOptions()

  return (
    <SelectMultiplyWithGroup
      label={label}
      open={open}
      collapseControl={handleCategoryClick}
      onClick={(value) => {
        setSearch('')
        setOpen(value)
      }}
      onClickItem={handleOptionSelect}
      onClickCategory={handleCategorySelect}
      onClickAll={handleSelectAll}
      allSelected={allSelected}
      onChange={search => setSearch(search)}
      items={itemsFiltered}
      hasError={hasError}
      errorMessage={errorMessage}
      values={arrayOptions}
      placeholder={placeholder}
      placeholderSearch={placeholderSearch}
      notFoundLabel={notFoundLabel}
      search={search}
      categoriesState={categoriesState}
    />
  )
}

SelectMultiplyWithGroupComponent.propTypes = {
  values: PropTypes.array,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderSearch: PropTypes.string,
  notFoundLabel: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  listItems: PropTypes.array
}

export default SelectMultiplyWithGroupComponent
