import React, { Fragment, useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  getTransactionById,
  resetTransactionActions,
  resetTransaction
} from 'src/Actions/transactions'
import {
  resetReceipt
} from 'src/Actions/receipt'
import Can from 'src/Helpers/Permission/Can'
import { hasReceipt } from 'src/Utils/saleUtils'
import { parseDate } from 'src/Utils/Utils'

import DateLoader from 'src/Components/SaleDetail/DateLoader'
import Grid from 'src/UIComponents/Grid/Grid'
import Collapse from 'src/UIComponents/Collapse/Collapse'
import Detail from 'src/Components/SaleDetail/Detail'
import History from 'src/UIComponents/SaleDetail/History/History'
import Payment from 'src/Components/SaleDetail/Payment'
import Receipt from 'src/Components/SaleDetail/Receipt/Receipt'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SuccessCard from 'src/UIComponents/SuccessCard/SuccessCard'
import { TransactionActionsMessageSuccess } from 'src/UIComponents/SuccessMessages/SuccessMessages'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import { getBuyer } from '../../Actions/seller'

const SaleDetail = ({ handleClose, handleGoBack, transactionId }) => {
  const [collapsed, setCollapsed] = useState(new Map())
  const [buyer, setBuyer] = useState({
    requesting: false,
    value: null
  })

  const dispatch = useDispatch()

  const {
    transactionRequesting,
    transactionError,
    transaction,
    voidTransactionAmount,
    cancelBoletoTransactionSuccess,
    captureTransactionAmount
  } = useSelector(({ transactions }) => transactions)

  useEffect(() => {
    dispatch(resetTransactionActions())
    dispatch(resetTransaction())
    dispatch(resetReceipt())
    dispatch(getTransactionById(transactionId))
  }, [transactionId])

  useEffect(() => {
    const call = async () => {
      if (transaction && transaction.id === transactionId && transaction.customer) {
        setBuyer({ ...buyer, requesting: true })
        const response = await getBuyer(transaction.customer)
        setBuyer({ requesting: false, value: response })
      }
    }

    call()
  }, [transaction])

  const handleCollapse = (section) => {
    let collapsedAux = new Map(collapsed)
    if (collapsedAux.has(section)) {
      collapsedAux.delete(section)
    } else {
      collapsedAux.set(section)
    }
    setCollapsed(collapsedAux)
  }

  if (
    voidTransactionAmount ||
    captureTransactionAmount ||
    cancelBoletoTransactionSuccess ||
    transactionError
  ) {
    let error = null

    if (transactionError) {
      error = {
        icon: transactionError.message.icon,
        title: transactionError.message.title,
        description: transactionError.message.description
      }

      if (transactionError.status === 404) {
        error = {
          icon: '/assets/icons/alerts/alert.svg',
          title: 'Desculpe, o detalhe desta venda não foi encontrado.',
          description: 'Erro 404'
        }
      }
    }

    return (
      <Fragment>
        <HeaderSidebar
          handleClose={handleClose}
          goBack={handleGoBack}
          title={
            <>
              <strong>Detalhe</strong> da venda
            </>
          }
        />
        <SidebarContainer>
          {!error && (
            <SuccessCard sizeButton='200px'>
              <TransactionActionsMessageSuccess
                onClick={() => {
                  dispatch(resetTransactionActions())
                  dispatch(getTransactionById(transactionId))
                }}
                type={
                  captureTransactionAmount
                    ? 'authorize'
                    : voidTransactionAmount
                      ? 'refund'
                      : cancelBoletoTransactionSuccess
                        ? 'cancelBoleto'
                        : 'cancel'
                }
                amount={
                  captureTransactionAmount ||
                  voidTransactionAmount
                }
              />
            </SuccessCard>
          )}
          {error && (
            <>
              <br />
              <br />
              <br />
              <br />
              <EmptyState
                titleSize='big'
                iconSrc={error.icon}
                title={error.title}
                description={error.description} />
            </>
          )}
        </SidebarContainer>
      </Fragment>
    )
  }

  const subtitle = () => {
    if (transactionRequesting) {
      return <DateLoader />
    } else if (transaction) {
      return parseDate(transaction.created_at, 'DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')
    }
  }

  return (
    <Fragment>
      <HeaderSidebar
        title={
          <>
            <strong>Detalhe</strong> da venda
          </>
        }
        subTitle={subtitle()}
        handleClose={handleClose}
        goBack={handleGoBack}
      />
      <SidebarContainer>
        <Grid.Row auto>
          <Detail sale={transaction} buyer={buyer.value} requesting={transactionRequesting || buyer.requesting} />
        </Grid.Row>
        <Grid.Row noMargin auto>
          <Collapse
            title={<><strong>Histórico</strong> da venda</>}
            data-test='transaction-detail-collapse-history'
            isOpen={collapsed.has('saleHistory')}
            onClick={() => {
              handleCollapse('saleHistory')
            }}>
            <History sale={transaction} requesting={transactionRequesting} />
          </Collapse>
        </Grid.Row>
        <Grid.Row noMargin auto>
          <Collapse
            title={<><strong>Recebimento</strong> da venda</>}
            data-test='transaction-detail-collapse-receivables'
            isOpen={collapsed.has('salePayment')}
            onClick={() => {
              handleCollapse('salePayment')
            }}>
            <Payment transactionId={transactionId} sale={transaction} transactionRequesting={transactionRequesting} />
          </Collapse>
        </Grid.Row>
        {transaction && hasReceipt(transaction) && (
          <Grid.Row auto>
            <Collapse
              title={<><strong>Comprovante</strong> da venda</>}
              data-test='transaction-detail-collapse-receipt'
              isOpen={collapsed.has('saleReceipt')}
              onClick={() => {
                handleCollapse('saleReceipt')
              }}>
              <Can I='create' a='Transaction' passThrough>
                {(can) => (
                  <Receipt
                    sale={transaction}
                    receiptId={transaction.sales_receipt}
                    enabledSendReceipt={can}
                  />
                )}
              </Can>
            </Collapse>
          </Grid.Row>
        )}
      </SidebarContainer>
    </Fragment>
  )
}

SaleDetail.propTypes = {
  handleClose: PropTypes.func,
  handleGoBack: PropTypes.func,
  transactionId: PropTypes.string
}

export default SaleDetail
