import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { v4 as UUID4 } from 'uuid'

import SelectSellerUI from 'src/UIComponents/SelectSeller/SelectSeller'
import { searchSellerSelect, getSellersSelect } from '../../Actions/sellers'
import {
  setFavoriteSeller,
  deleteFavoriteSeller
} from '../../Actions/favorites'
import { useDispatch, useSelector } from 'react-redux'
import DisplaySeller from '../../UIComponents/SelectSeller/DisplaySeller'

const SelectSeller = ({
  showDisplay,
  action,
  hasError,
  errorMessage,
  placeholder,
  border,
  planId,
  noBox,
  loadMore,
  showTotals,
  handleError,
  showHint,
  showFavorites,
  showSellerAssociatedBadge,
  ...props
}) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [limit] = useState(100)
  const [sellers, setSellers] = useState([])

  const {
    favoriteSellers,
    favoriteSellersRequesting,
    sellersSearch,
    sellersSearchRequesting,
    totalSellers,
    context,
    sellerName,
    planSellers,
    sellersPlanRequesting
  } = useSelector(({ sellers, context, favorites, plans }) => ({
    favoriteSellers: sortBy(favorites.favoriteSellers, 'name'),
    favoriteSellersRequesting: favorites.favoriteSellersRequesting,
    sellersSearch: sellers.sellersSearch,
    sellersSearchRequesting: sellers.sellersSearchRequesting,
    totalSellers: sellers.sellersSearchCount,
    sellerName:
      context.seller &&
      (context.seller?.business_name ||
        `${context.seller?.first_name} ${context.seller?.last_name}`),
    planSellers: plans.sellersPlan,
    sellersPlanRequesting: plans.sellersPlanRequesting,
    context
  }))

  useEffect(() => {
    dispatch(getSellersSelect({ offset: 0 }))
  }, [])

  useEffect(() => {
    if (!sellersSearchRequesting) {
      let serializedSellers = sellersSearch
      if (showFavorites) {
        serializedSellers = removeDuplicatedAndNotSearchedSellers()
      }

      if (showSellerAssociatedBadge && !sellersPlanRequesting) {
        serializedSellers = concatWithplanSellers(serializedSellers)
      }

      setSellers(serializedSellers)
    }
  }, [
    sellersSearchRequesting,
    favoriteSellers.length,
    sellersPlanRequesting,
    planSellers
  ])

  useEffect(() => {
    if (search && !isOpen) {
      setSearch('')
    }
  }, [isOpen])

  const handleAddFavorite = (seller) => {
    dispatch(setFavoriteSeller(seller))
  }

  const handleDeleteFavorite = (seller) => {
    dispatch(deleteFavoriteSeller(seller))
  }

  const handleSelect = (e, item) => {
    e.preventDefault()
    setSearch('')
    dispatch(getSellersSelect({ offset: 0 }))
    if (handleError) {
      handleError()
    }
    action(item)
  }

  const handleSearch = (e) => {
    const query = e.target.value
    setSearch(query)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchSeller(search)
    }
  }

  const getSearchedFavorites = () => {
    if (search) {
      const searchCleaned = search
        .trim()
        .replace(/[.,\-,/]/g, '')
        .toLowerCase()

      if (Number(searchCleaned) && searchCleaned.length === 11) {
        return favoriteSellers.filter(
          (favorite) => favorite.taxpayer_id === searchCleaned
        )
      } else if (Number(searchCleaned) && searchCleaned.length === 14) {
        return favoriteSellers.filter(
          (favorite) => favorite.ein === searchCleaned
        )
      } else {
        return favoriteSellers.filter((favorite) =>
          favorite.name.toLowerCase().includes(searchCleaned)
        )
      }
    }
    return favoriteSellers
  }

  const searchSeller = (searchTerm) => {
    const params = {
      offset: 0,
      limit
    }

    const searchCleaned = searchTerm.trim().replace(/[.,\-,/]/g, '')

    if (Number(searchCleaned) && searchCleaned.length === 11) {
      dispatch(
        searchSellerSelect({
          ...params,
          taxpayer_id: searchCleaned
        })
      )
    } else if (Number(searchCleaned) && searchCleaned.length === 14) {
      dispatch(
        searchSellerSelect({
          ...params,
          ein: searchCleaned
        })
      )
    } else {
      dispatch(
        getSellersSelect({
          ...params,
          name: searchCleaned.toLowerCase()
        })
      )
    }
  }

  const concatWithplanSellers = (sellers) => {
    return sellers.reduce((acc, curr) => {
      const alreadyAssociated = planSellers.find((item) => item.id === curr.id)
      if (alreadyAssociated) {
        return acc
      } else {
        return acc.concat([curr])
      }
    }, [])
  }

  const removeDuplicatedAndNotSearchedSellers = () => {
    const searchedFavorites = getSearchedFavorites()

    const allSellers = [...searchedFavorites, ...sellersSearch]

    return allSellers.reduce((acc, curr) => {
      const alreadyExists = acc.find((item) => item.id === curr.id)

      if (!alreadyExists) {
        return acc.concat([curr])
      } else {
        return acc
      }
    }, [])
  }

  return (
    <SelectSellerUI
      {...props}
      display={
        showDisplay ? (
          <DisplaySeller
            sellerName={sellerName}
            open={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        ) : null
      }
      uuid={UUID4()}
      value={search}
      items={sellers}
      action={handleSelect}
      onChange={handleSearch}
      onKeyDown={handleKeyDown}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={placeholder}
      border={border}
      planId={planId}
      noBox={noBox}
      isLoading={sellersSearchRequesting || favoriteSellersRequesting}
      loadMore={loadMore}
      totalSellers={totalSellers}
      openDefault={isOpen}
      context={context}
      onClose={() => setIsOpen(false)}
      showTotals={showTotals}
      setFavorite={handleAddFavorite}
      deleteFavorite={handleDeleteFavorite}
      searchFunction={searchSeller}
      showHint={showHint}
      showFavorites={showFavorites}
      data-tracking={props['data-tracking'] || 'pos-seller-input'}
    />
  )
}

SelectSeller.propTypes = {
  showDisplay: PropTypes.bool,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  action: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  planId: PropTypes.string,
  noBox: PropTypes.bool,
  loadMore: PropTypes.func,
  showTotals: PropTypes.bool,
  showHint: PropTypes.bool,
  handleError: PropTypes.func,
  showFavorites: PropTypes.bool
}

export { SelectSeller }
