import React from 'react'
import * as PropTypes from 'prop-types'

import SaleInfoCardUI from 'src/UIComponents/SaleDetail/Detail/SaleInfoCard'
import { SaleActions } from 'src/Components/SaleDetail/SaleActions'
import Grid from 'src/UIComponents/Grid/Grid'
import {
  isProcessingVoid,
  isAbleToAuthorize,
  isAbleToCancel,
  isAbleToPartialCancel,
  isEligibleToCancelledLetter
} from '../../Utils/saleUtils'

import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'

const SaleInfoCard = ({ sale }) => {
  return (
    <Grid noPadding>
      {isProcessingVoid(sale.history) ? (
        <AlertMessage
          type='info'
          info={{
            title: 'PROCESSANDO',
            message: 'Seu cancelamento está sendo processado.'
          }}
        />
      ) : (
        (isAbleToAuthorize(sale) ||
          isAbleToCancel(sale) ||
          isAbleToPartialCancel(sale) ||
          isEligibleToCancelledLetter(sale.history)) && (
          <Grid.Row auto>
            <SaleActions sale={sale} />
          </Grid.Row>
        )
      )}
      <Grid.Row />
      <Grid.Row />
      <Grid.Row auto>
        <SaleInfoCardUI sale={sale} />
      </Grid.Row>
    </Grid>
  )
}

SaleInfoCard.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoCard
