import React from 'react'
import * as PropTypes from 'prop-types'
import SellerAdditionalInfoTemplate from 'src/UIComponents/TemplatesForm/SellerAdditionalInfoTemplate'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import t from 'tcomb-form'
import formValidators from 'src/Utils/formValidators'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'

const SellerAdditionalInfoComponent = ({
  values
}) => {
  let options = {
    template: (locals) => SellerAdditionalInfoTemplate(locals),
    fields: {
      sellerId: {
        label: 'Credenciamento do vendedor',
        factory: InputDefaultFactory,
        config: {
          id: 'seller-id',
          disabled: true
        }
      },
      referenceId: {
        label: 'ID de Referência',
        factory: InputDefaultFactory,
        config: {
          id: 'reference-id',
          disabled: true
        }
      }
    }
  }

  const struct = t.struct({
    sellerId: formValidators.String,
    referenceId: formValidators.String
  })

  return (
    <FormZ
      options={options}
      struct={struct}
      values={values}
      disabledForm
    />
  )
}

SellerAdditionalInfoComponent.propTypes = {
  values: PropTypes.object
}

export default SellerAdditionalInfoComponent
