import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPlanDetails, resetPlanDetails } from '../../Actions/plans'

import {
  debitTab,
  creditTab,
  boletoTab,
  loadingTax,
  pixTab
} from 'src/Components/PlansDetailComponent/TemplatesTableDetailPlan'
import BlankState from '../../UIComponents/BlankState/BlankState'
import Grid from 'src/UIComponents/Grid/Grid'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import Title from 'src/UIComponents/Title/Title'
import LabelLoader from 'src/UIComponents/Loader/LabelLoader'

import { darkGray } from 'src/Styles/settings/Constants'
import { nupayTab } from '../PlansDetailComponent/TemplatesTableDetailPlan'

const PlanSeller = () => {
  const dispatch = useDispatch()
  const {
    marketplaceId,
    sellerActivePlanSuccess,
    sellerActivePlan,
    planDetailsRequesting,
    planDetailsData,
    planDetailsError,
    color
  } = useSelector(({ marketplace, plans, custom }) => ({
    marketplaceId: marketplace.details.id,
    sellerActivePlanSuccess: plans.sellerActivePlanSuccess,
    sellerActivePlan: plans.sellerActivePlan,
    planDetailsRequesting: plans.planDetailsRequesting,
    planDetailsData: plans.planDetailsData,
    planDetailsError: plans.planDetailsError,
    color: custom.color
  }))

  const [titles, setTitles] = useState()

  useEffect(() => {
    if (sellerActivePlan && sellerActivePlan.id) {
      dispatch(getPlanDetails(marketplaceId, sellerActivePlan.id))
    }
    return () => dispatch(resetPlanDetails())
  }, [sellerActivePlan])

  useEffect(() => {
    if (planDetailsData) {
      setTitles(getTitlesTabs())
    }
  }, [planDetailsData])

  const getTitlesTabs = () => {
    if (planDetailsData && planDetailsData.fee_details) {
      const feeDetailsKeys = Object.keys(planDetailsData.fee_details)
      const feeKeys = feeDetailsKeys.map((feeKey) => {
        if (feeKey === 'debit') return 'Débito'
        if (feeKey === 'credit') return 'Crédito'
        if (feeKey === 'boleto') return 'Boleto Bancário'
        if (feeKey === 'pix') return 'Pix'
        if (feeKey === 'nupay') return 'Nupay'
      })

      return feeKeys.sort()
    }
  }

  if (planDetailsError) {
    const title =
      planDetailsError.message.title || 'Alguma coisa aconteceu de errado'
    const description =
      planDetailsError.message.description ||
      planDetailsError.message ||
      'Não se preocupe, seus dados não foram afetados. Estamos trabalhando para evitar que se repita.'
    const iconPath =
      planDetailsError.message.icon || '/assets/icons/alerts/alert.svg'
    return (
      <Grid.Col cols={16}>
        <EmptyState
          title={title}
          description={description}
          iconColor={darkGray}
          iconSrc={iconPath}
        />
      </Grid.Col>
    )
  }

  if (sellerActivePlanSuccess && !sellerActivePlan) {
    return (
      <Fragment>
        <Grid.Row auto largeMarginBottom />
        <Grid.Row auto>
          <BlankState
            icon='/assets/icons/others/tax.svg'
            message='Nenhum plano está ativo para este estabelecimento'
          />
        </Grid.Row>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {!titles ||
      !planDetailsData ||
      !sellerActivePlan ||
      planDetailsRequesting ? (
        <Fragment>
          <Grid.Row bigMarginBottom>
            <Grid.Col cols={2}>
              <LabelLoader height='large' />
            </Grid.Col>
          </Grid.Row>
          {loadingTax()}
        </Fragment>
      ) : (
        <Fragment>
          <Grid.Row auto largeMarginBottom>
            <Title big noPadding data-test='seller-detail-plan-title'>
              <strong>{sellerActivePlan.name}</strong>
            </Title>
          </Grid.Row>
          {planDetailsData.fee_details && typeof titles === 'object' && (
            <Fragment>
              {titles.includes('Débito') && (
                <Fragment>
                  {debitTab(planDetailsData.fee_details.debit, color)}
                </Fragment>
              )}

              {titles.includes('Crédito') && (
                <Fragment>
                  {creditTab(planDetailsData.fee_details.credit, color)}
                </Fragment>
              )}

              {titles.includes('Boleto Bancário') && (
                <Fragment>
                  {boletoTab(planDetailsData.fee_details.boleto, color)}
                </Fragment>
              )}

              {titles.includes('Pix') && (
                <Fragment>
                  {pixTab(planDetailsData.fee_details.pix, color)}
                </Fragment>
              )}

              {titles.includes('Nupay') && (
                <Fragment>
                  {nupayTab(planDetailsData.fee_details.nupay, color)}
                </Fragment>
              )}
            </Fragment>
          )}
          {typeof titles === 'string' && (
            <Fragment>
              <Grid.Row auto largeMarginBottom />
              <Grid.Row auto>
                <BlankState
                  icon='/assets/icons/others/tax.svg'
                  message='Nenhuma taxa vinculada ao plano'
                />
              </Grid.Row>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default PlanSeller
