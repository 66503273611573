import React from 'react'
import { useSelector } from 'react-redux'

import SellerHeaderInformationUI from 'src/UIComponents/SellerHeaderInformation/SellerHeaderInformation'

const SellerHeaderInformation = () => {
  const { sellerInformation, isLoading } = useSelector(({ context }) => {
    return {
      sellerInformation: context.seller,
      isLoading: context.sellerContextChangeRequesting
    }
  })
  return (
    <SellerHeaderInformationUI seller={sellerInformation} isLoading={isLoading || !sellerInformation} />
  )
}

export default SellerHeaderInformation
