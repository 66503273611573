import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import SelectBankCode from '../../UIComponents/SelectBankCode/SelectBankCode'

const SelectBankCodeComponent = ({
  label,
  hasError,
  errorMessage,
  items,
  onChange,
  uuid
}) => {
  const [open, setOpen] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [search, setSearch] = useState()
  const [filteredItems, setFilteredItems] = useState(items)

  const handleSelect = e => {
    e && e.stopPropagation()

    setOpen(!open)
    setSearch(null)
  }

  SelectBankCodeComponent[`${uuid}-handleClickOutside`] = () => {
    setOpen(false)
    setSearch(null)
  }

  useEffect(() => {
    const regex = new RegExp(search, 'gi')
    setFilteredItems(!search ? items : items.filter(i => {
      return i.code.match(regex) || i.name.match(regex)
    }))
  }, [items, search])

  const selectedItem = useCallback((item) => {
    setSelectedOption(item)
    onChange(item)
    setSearch(null)
    setOpen(false)
  }, [setSelectedOption, onChange, setSearch, setOpen])

  const handleSearchChange = useCallback((value) => setSearch(value), [setSearch])

  return (
    <SelectBankCode
      label={label}
      open={open}
      onClick={handleSelect}
      onSelect={selectedItem}
      onSearchChange={handleSearchChange}
      items={filteredItems}
      selected={selectedOption}
      hasError={hasError}
      errorMessage={errorMessage}
      search={search}
      onArrowClick={handleSelect}
    />
  )
}

const clickOutsideConfig = {
  handleClickOutside: ({ props: { uuid } }) => SelectBankCodeComponent[`${uuid}-handleClickOutside`]
}

SelectBankCodeComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  items: PropTypes.array,
  errorMessage: PropTypes.string,
  uuid: PropTypes.string.required
}

export default onClickOutside(SelectBankCodeComponent, clickOutsideConfig)
