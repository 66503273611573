import React from 'react'
import ContentLoader from 'react-content-loader'

const SelectBankAccountLoader = (props) => (
  <ContentLoader
    speed={4}
    width={400}
    height={278}
    viewBox='0 0 400 278'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='96' rx='12' ry='12' width='400' height='86' />
    <rect x='0' y='0' rx='12' ry='12' width='400' height='86' />
    <rect x='0' y='192' rx='12' ry='12' width='400' height='86' />
  </ContentLoader>
)

export default SelectBankAccountLoader
