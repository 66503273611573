import React from 'react'

import { SelectSeller } from 'src/Components/SelectSeller/SelectSeller'
import { useHistory } from 'react-router-dom'
import { useSellerContext } from '../../Hooks/useSellerContext'

const SelectSellerContext = () => {
  useSellerContext()

  const history = useHistory()

  const handleChangeSeller = (seller) => {
    if (!seller) {
      history.push('/')
    } else {
      history.push(`/estabelecimento/${seller?.id}`)
    }
  }

  return (
    <SelectSeller
      action={handleChangeSeller}
      data-tracking='filter-seller'
      data-test='select-seller-context'
      showTotals
      showDisplay
      showHint
      showFavorites
    />
  )
}

export default SelectSellerContext
