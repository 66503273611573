import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTransactionsSucceededAmountBySeller } from 'src/Actions/kpi'
import { getSellerDocument } from 'src/Actions/seller'
import { getSellerActivePlan } from 'src/Actions/plans'
import { statusLabels } from 'src/Constants/StatusLabels'

import { HeaderSellerDetail } from 'src/UIComponents/HeaderSellerDetail'
import { Tabs, TabList, Tab, TabPanel } from 'src/UIComponents/Tabs/Tabs'
import ProfileSeller from 'src/Components/Seller/ProfileSeller'
import PlanSeller from 'src/Components/Seller/PlanSeller'
import DocumentsSeller from 'src/Components/Seller/DocumentsSeller'
import BankAccountsComponent from 'src/Components/BankAccountsComponent/BankAccountsComponent'
import { StepStatusBySeller } from 'src/UIComponents/StepStatusBySeller'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { getApiSettings } from '../../Actions/settings'

const SellerConfiguration = () => {
  const dispatch = useDispatch()
  const [tabIndex, setTabIndex] = useState(null)
  const history = useHistory()

  const { url, params } = useRouteMatch({
    path: '*/configuracoes/:slug?'
  })

  const tabs = [
    {
      title: 'Dados Cadastrais',
      slug: 'dados-cadastrais'
    },
    {
      title: 'Taxas do Plano',
      slug: 'taxas-do-plano'
    },
    {
      title: 'Documentos',
      slug: 'documentos'
    },
    {
      title: 'Meios de Recebimento',
      slug: 'meios-de-recebimento'
    }
  ]

  const {
    seller,
    sellerActivePlanSuccess,
    sellerActivePlan,
    sellerActivePlanError,
    sellerKpi,
    documents
  } = useSelector(({ context, seller, plans, kpi }) => ({
    seller: context.seller,
    sellerActivePlanRequesting: plans.sellerActivePlanRequesting,
    sellerActivePlan: plans.sellerActivePlan,
    sellerActivePlanSuccess: plans.sellerActivePlanSuccess,
    sellerActivePlanError: plans.sellerActivePlanError,
    sellerKpi: kpi.seller,
    documents: seller.documents
  }))

  useEffect(() => {
    dispatch(getApiSettings(true))
    if (!params.slug) {
      history.push(`${url}/dados-cadastrais`)
    }
  }, [])

  useEffect(() => {
    if (seller) {
      dispatch(getSellerActivePlan(seller.id))
      dispatch(getSellerDocument(seller.id))
    }
  }, [seller])

  useEffect(() => {
    if (seller) {
      dispatch(getTransactionsSucceededAmountBySeller(seller))
    }
  }, [seller])

  useEffect(() => {
    if (params.slug) {
      setTabIndex(tabs.findIndex(value => value.slug === params.slug))
    } else {
      setTabIndex(0)
    }
  }, [url])

  const getActivePlanResumeInfo = () => {
    if (sellerActivePlan) {
      return sellerActivePlan.name
    }

    if (sellerActivePlanError) {
      return 'Clique aqui para recarregar.'
    }
  }

  const getActivePlanResumeTitle = () => {
    if (sellerActivePlanSuccess && !sellerActivePlan) {
      return 'Nenhum plano está ativo para\n este estabelecimento'
    }

    if (sellerActivePlanError) {
      return 'Desculpe, tivemos um problema'
    }

    return null
  }

  const handlerOnSelectTab = (tabIndex) => {
    history.push(`${params[0]}/configuracoes/${tabs[tabIndex].slug}`)
  }

  const infoResume = [
    {
      isEmpty: false,
      label: 'Status',
      info: seller && statusLabels[seller.status].label,
      color: seller && statusLabels[seller.status].color,
      onClick: () => handlerOnSelectTab(2)
    },
    {
      isEmpty: sellerActivePlanError || (sellerActivePlanSuccess && !sellerActivePlan),
      label: !sellerActivePlanError && 'Plano Ativo',
      info: getActivePlanResumeInfo(),
      title: getActivePlanResumeTitle(),
      boldTitle: sellerActivePlanError,
      onClick: sellerActivePlanError ? () => dispatch(getSellerActivePlan(seller.id)) : () => handlerOnSelectTab(1),
      icon: sellerActivePlanError && '/assets/icons/actions/reload.svg'
    }
  ]

  return (
    <>
      <HeaderSellerDetail item={seller} infoResume={infoResume} isRequesting={!seller || sellerKpi.transactions.request} />
      {seller && (seller.status === 'pending' || seller.status === 'denied') &&
        <StepStatusBySeller
          active={seller.status === 'pending' || seller.status === 'denied'}
          send={documents?.length > 0 || seller.status === 'denied'}
          denied={seller.status === 'denied'}
        />
      }
      {tabIndex !== null && <Tabs selectedIndex={tabIndex} onSelect={tabIndex => handlerOnSelectTab(tabIndex)}>
        <TabList data-margin-bottom>
          <Tab data-tracking={`Estabelecimentos > Detalhe > ${tabs[0].title}`} uppercase data-test='seller-detail-info'>
            {tabs[0].title}
          </Tab>
          <Tab data-tracking={`Estabelecimentos > Detalhe > ${tabs[1].title}`} uppercase data-test='seller-detail-plans'>
            {tabs[1].title}
          </Tab>
          <Tab data-tracking={`Estabelecimentos > Detalhe > ${tabs[2].title}`} uppercase data-test='seller-detail-document'>
            {tabs[2].title}
          </Tab>
          <Tab data-tracking={`Estabelecimentos > Detalhe > ${tabs[3].title}`} uppercase data-test='seller-detail-bank'>
            {tabs[3].title}
          </Tab>
        </TabList>
        <TabPanel>
          <ProfileSeller seller={seller} />
        </TabPanel>
        <TabPanel>
          <PlanSeller />
        </TabPanel>
        <TabPanel>
          <DocumentsSeller />
        </TabPanel>
        <TabPanel>
          <BankAccountsComponent data-tracking={`Estabelecimentos > Detalhe > Meios de recebimento`} sellerView />
        </TabPanel>
      </Tabs>}
    </>
  )
}

SellerConfiguration.propTypes = {}

export default SellerConfiguration
