import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import UIPayment from 'src/UIComponents/SaleDetail/Payment'
import * as transactionsActions from 'src/Actions/transactions'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import PaymentLoader from 'src/Components/SaleDetail/PaymentLoader'

const mapDispatchToProps = dispatch => bindActionCreators(
  transactionsActions,
  dispatch
)

const mapStateToProps = (state) => {
  const {
    receivablesRequesting,
    receivablesError,
    receivables,
    receivablesTotalItems,
    receivablesPageSize
  } = state.transactions

  return {
    receivablesRequesting,
    receivablesError,
    receivables,
    receivablesTotalItems,
    receivablesPageSize
  }
}

const Payment = ({
  transactionId,
  sale,
  getReceivables,
  receivables,
  receivablesRequesting,
  receivablesTotalItems,
  receivablesPageSize,
  transactionRequesting
}) => {
  useEffect(() => {
    getReceivables(transactionId, { limit: receivablesPageSize })
  }, [])

  if (receivablesRequesting || transactionRequesting || !sale) {
    return <PaymentLoader />
  } else if (receivablesTotalItems === 0 && sale.payment_method) {
    const {
      payment_type: paymentType,
      payment_method: {
        card_brand: cardBrand
      }
    } = sale

    if (paymentType === 'voucher') {
      const cardBrandGender = {
        'Alelo': 'female',
        'Ticket': 'male',
        'VR': 'male',
        'Sodexo': 'male'
      }

      const ofPrepositionByGender = {
        'male': 'do',
        'female': 'da'
      }

      const byPrepositionByGender = {
        'male': 'pelo',
        'female': 'pela'
      }

      const byPreposition = byPrepositionByGender[cardBrandGender[cardBrand]]
      const ofPreposition = ofPrepositionByGender[cardBrandGender[cardBrand]]

      const cardBrandUrl = {
        'Alelo': 'https://meuestabelecimento.alelo.com.br/',
        'Ticket': 'https://www.ticket.com.br/portal-estabelecimento/login',
        'VR': 'https://www.vr.com.br/portal/portal-vr/login/',
        'Sodexo': 'https://www.sodexobeneficios.com.br/estabelecimentos/login/'
      }

      return (
        <EmptyState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Os recebíveis desta venda não ficam visíveis aqui'
          description={`O pagamento da venda será feito diretamente ${byPreposition} ${cardBrand},\n o seu estabelecimento deverá acessar <b>o site da bandeira</b> para consultar o recebível.`}
          actionTitle={`Ir para o site ${ofPreposition} ${cardBrand}`}
          action={() => window.open(cardBrandUrl[cardBrand], '_blank')} />
      )
    } else {
      return (
        <EmptyState
          iconSrc='/assets/icons/actions/switch.svg'
          title='Esta venda ainda não gerou recebíveis'
          description={`Só podemos fazer a transferência do\n valor da venda após a sua aprovação`}
          uppercaseTitle />
      )
    }
  } else {
    return (
      <UIPayment sale={sale} receivables={receivables} />
    )
  }
}

Payment.propTypes = {
  transactionId: PropTypes.string,
  sale: PropTypes.object,
  getReceivables: PropTypes.func,
  receivables: PropTypes.array,
  receivablesRequesting: PropTypes.bool,
  receivablesTotalPages: PropTypes.number,
  receivablesTotalItems: PropTypes.any,
  receivablesPageSize: PropTypes.number,
  transactionRequesting: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
