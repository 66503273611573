import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getSellersByPlan } from '../../Actions/plans'

import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import Grid from '../../UIComponents/Grid/Grid'
import ListSellers from '../../UIComponents/ListSellers/ListSellers'

import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import Dots from 'src/UIComponents/Dots/Dots'

const SellersPlansComponent = ({ handleClose }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const limit = 25

  const {
    planId,
    sellersPlan,
    setSellerPlanSuccess,
    totalSellers
  } = useSelector(({ plans }) => ({
    planId: plans.planDetailsData.id,
    sellersPlan: [...plans.sellersPlan],
    totalSellers: plans.totalSellers,
    setSellerPlanSuccess: plans.setSellerPlanSuccess

  }))

  useEffect(() => {
    if (setSellerPlanSuccess) {
      setPage(0)
    }
  }, [setSellerPlanSuccess])

  const fetchMoreData = () => {
    const newPage = page + 1
    setPage(newPage)
    dispatch(getSellersByPlan(planId, { limit, offset: newPage * limit }, true))
  }

  return (
    <Fragment>
      <HeaderSidebar
        title={<><strong>Estabelecimentos</strong> Associados</>}
        handleClose={handleClose}
      />
      {/* esse style é necessário para que o infinity-scroll funcione corretamente */}
      <SidebarContainer noPadding id='SellerPlansSidebar' style={{ overflow: 'scroll', overflowX: 'auto' }}>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <InfiniteScroll
              dataLength={sellersPlan.length}
              next={fetchMoreData}
              hasMore={sellersPlan.length < totalSellers}
              loader={<Dots outline />}
              scrollableTarget={'SellerPlansSidebar'}
            >
              <ListSellers data={sellersPlan} />
            </InfiniteScroll>
          </Grid.Col>
        </Grid.Row>
      </SidebarContainer>
    </Fragment>
  )
}

SellersPlansComponent.propTypes = {
  handleClose: PropTypes.func
}

export default SellersPlansComponent
