import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import FormZ from 'src/UIComponents/FormZ/FormZ'
import formValidators from 'src/Utils/formValidators'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import SellerInfoBusinessTemplate from '../../UIComponents/TemplatesForm/SellerInfoBusinessTemplate'
import DocumentFactory from 'src/Components/FormFactories/DocumentFactory'
import PhoneFactory from 'src/Components/FormFactories/PhoneFactory'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import MoneyFactory from 'src/Components/FormFactories/MoneyFactory'

export const struct = t.struct({
  ein: t.maybe(t.String),
  business_description: formValidators.String,
  business_phone: formValidators.Phone,
  business_email: t.maybe(t.String),
  mcc: t.maybe(t.String),
  business_name: t.maybe(t.String),
  statement_descriptor: formValidators.String,
  business_website: t.maybe(t.String),
  created_at: t.maybe(t.String),
  revenue: t.maybe(t.String)
})

const SellerInfoBusiness = ({
  values,
  onChange,
  showValidation,
  editMode
}) => {
  const [showFormValidation, setShowFormValidation] = useState(false)

  useEffect(() => {
    setShowFormValidation(showValidation)
  }, [showValidation])

  const handleOnChange = (e) => {
    onChange({
      infosBusiness: {
        ...e
      }
    })
  }

  const formOptions = {
    values: values,
    options: {
      template: (locals) => SellerInfoBusinessTemplate(locals),
      fields: {
        ein: {
          label: 'CNPJ',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'ein',
            documentType: 'ein',
            disabled: true,
            lock: true
          }
        },
        business_name: {
          label: 'Razão Social',
          factory: InputDefaultFactory,
          config: {
            id: 'business_name',
            disabled: true,
            lock: true
          }
        },
        statement_descriptor: {
          label: 'Nome Fantasia (usado na fatura do cartão)',
          factory: InputDefaultFactory,
          config: {
            id: 'statement_descriptor',
            disabled: !editMode
          }
        },
        created_at: {
          label: 'Data de Criação',
          factory: InputDefaultFactory,
          config: {
            id: 'created_at',
            disabled: true,
            lock: true
          }
        },
        mcc: {
          label: 'Categoria do Negório',
          factory: InputDefaultFactory,
          config: {
            id: 'mcc',
            disabled: true,
            lock: true
          }
        },
        business_email: {
          label: 'E-mail',
          factory: InputDefaultFactory,
          config: {
            id: 'email',
            disabled: true,
            lock: true
          }
        },
        business_phone: {
          label: 'Telefone',
          factory: PhoneFactory,
          autocomplete: false,
          config: {
            id: 'business_phone',
            disabled: !editMode
          }
        },
        business_description: {
          label: 'Descrição',
          factory: InputDefaultFactory,
          config: {
            id: 'description',
            disabled: !editMode
          }
        },
        business_website: {
          label: 'Site (opcional)',
          factory: InputDefaultFactory,
          config: {
            id: 'website',
            disabled: !editMode
          }
        },
        revenue: {
          label: 'Faturamento',
          factory: MoneyFactory,
          config: {
            id: 'revenue',
            disabled: true,
            lock: true
          }
        }
      }
    }
  }

  return (
    <FormZ
      options={showFormValidation
        ? getFormOptionsWithValidation(formOptions.options, formOptions.values, struct)
        : formOptions.options}
      struct={struct}
      values={formOptions.values}
      onChange={handleOnChange}
      refs='infoForm'
      disabledForm={!editMode}
    />
  )
}

SellerInfoBusiness.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  editMode: PropTypes.bool
}

export { SellerInfoBusiness }
