import React from 'react'
import ContentLoader from 'react-content-loader'

const ResumeLoader = (props) => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={600}
    viewBox='0 0 380 600'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='0' rx='0' ry='0' width='71' height='17' />
    <rect x='0' y='20' rx='0' ry='0' width='74' height='37' />
    <rect x='100' y='20' rx='0' ry='0' width='74' height='37' />
    <rect x='220' y='21' rx='0' ry='0' width='74' height='37' />
    <rect x='0' y='80' rx='0' ry='0' width='71' height='17' />
    <rect x='90' y='80' rx='0' ry='0' width='111' height='17' />
    <rect x='220' y='75' rx='0' ry='0' width='33' height='30' />
    <rect x='0' y='117' rx='0' ry='0' width='84' height='17' />
    <rect x='0' y='137' rx='0' ry='0' width='327' height='17' />
    <rect x='0' y='202' rx='0' ry='0' width='116' height='17' />
    <rect x='30' y='234' rx='0' ry='0' width='100' height='17' />
    <rect x='30' y='254' rx='0' ry='0' width='184' height='17' />
    <rect x='30' y='285' rx='0' ry='0' width='100' height='17' />
    <rect x='30' y='307' rx='0' ry='0' width='184' height='17' />
    <rect x='254' y='287' rx='0' ry='0' width='100' height='17' />
    <rect x='254' y='307' rx='0' ry='0' width='71' height='17' />
    <rect x='30' y='338' rx='0' ry='0' width='113' height='17' />
    <rect x='30' y='358' rx='0' ry='0' width='41' height='15' />
    <rect x='0' y='418' rx='0' ry='0' width='131' height='19' />
    <rect x='30' y='449' rx='0' ry='0' width='100' height='17' />
    <rect x='30' y='468' rx='0' ry='0' width='169' height='19' />
    <rect x='213' y='449' rx='0' ry='0' width='58' height='17' />
    <rect x='213' y='470' rx='0' ry='0' width='122' height='19' />
    <rect x='30' y='500' rx='0' ry='0' width='100' height='17' />
    <rect x='30' y='520' rx='0' ry='0' width='259' height='19' />
    <rect x='30' y='554' rx='0' ry='0' width='100' height='17' />
    <rect x='30' y='573' rx='0' ry='0' width='284' height='21' />
  </ContentLoader>
)

export default ResumeLoader
