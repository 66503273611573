import React from 'react'
import ContentLoader from 'react-content-loader'

const PaymentLoader = (props) => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={350}
    viewBox='0 0 380 350'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='25' y='5' rx='0' ry='0' width='165' height='86' />
    <rect x='198' y='5' rx='0' ry='0' width='165' height='86' />
    <rect x='23' y='133' rx='0' ry='0' width='68' height='37' />
    <rect x='104' y='140' rx='0' ry='0' width='59' height='22' />
    <rect x='176' y='140' rx='0' ry='0' width='59' height='22' />
    <rect x='266' y='140' rx='0' ry='0' width='84' height='22' />
    <rect x='20' y='190' rx='0' ry='0' width='74' height='20' />
    <rect x='20' y='235' rx='0' ry='0' width='74' height='20' />
    <rect x='20' y='280' rx='0' ry='0' width='74' height='20' />
    <rect x='20' y='320' rx='0' ry='0' width='74' height='20' />
    <rect x='110' y='190' rx='0' ry='0' width='33' height='20' />
    <rect x='110' y='235' rx='0' ry='0' width='33' height='20' />
    <rect x='110' y='280' rx='0' ry='0' width='33' height='20' />
    <rect x='110' y='320' rx='0' ry='0' width='33' height='20' />
    <rect x='166' y='190' rx='0' ry='0' width='74' height='20' />
    <rect x='166' y='235' rx='0' ry='0' width='74' height='20' />
    <rect x='166' y='280' rx='0' ry='0' width='74' height='20' />
    <rect x='166' y='320' rx='0' ry='0' width='74' height='20' />
    <rect x='272' y='190' rx='0' ry='0' width='67' height='20' />
    <rect x='272' y='235' rx='0' ry='0' width='67' height='20' />
    <rect x='272' y='280' rx='0' ry='0' width='67' height='20' />
    <rect x='272' y='320' rx='0' ry='0' width='67' height='20' />
  </ContentLoader>
)

export default PaymentLoader
