import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import { Prompt, useHistory } from 'react-router-dom'
import t from 'tcomb-form'
import moment from 'moment'

import Can from '../../Helpers/Permission/Can'
import { updateBusiness, removeSeller } from 'src/Actions/seller'
import { SellerAddress, struct as AddressStruct } from './SellerAddress'
import {
  SellerInfoIndividual,
  struct as InfoIndividualStruct
} from './SellerInfoIndividual'
import {
  SellerInfoBusiness,
  struct as InfoBusinessStruct
} from './SellerInfoBusiness'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Grid from 'src/UIComponents/Grid/Grid'
import Disclaimer from 'src/UIComponents/Disclaimer/Disclaimer'
import {
  SellerOwnerData,
  struct as OwnerStruct
} from 'src/Components/Seller/SellerOwnerData'
import LineCard from 'src/UIComponents/LineCard/LineCard'
import { lightestGray } from 'src/Styles/settings/Constants'
import { getCategoryCodes } from '../../Actions/merchantCategoryCodes'
import SellerAdditionalInfoComponent from 'src/Components/Seller/SellerAdditionalInfoComponent'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const ProfileSeller = ({ seller }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [hasSavedChangesInLeaveRoute, setHasSavedChangesInLeaveRoute] =
    useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(null)
  const [showValidation, setShowValidation] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [values, setValues] = useState()
  const [showMFA, setShowMFA] = useState(false)

  const {
    deleteSellerRequest,
    deleteSellerSuccess,
    sellerContext,
    isSellerDetailsRequesting,
    sellerDetailSuccess,
    mccData
  } = useSelector(({ context, seller, merchantCategoryCodes }) => ({
    deleteSellerRequest: seller.deleteSellerRequest,
    deleteSellerSuccess: seller.deleteSellerSuccess,
    sellerContext: context.seller,
    isSellerDetailsRequesting: seller.isSellerDetailsRequesting,
    sellerDetailSuccess: seller.sellerDetailSuccess,
    mccData: merchantCategoryCodes.mccData
  }))

  const profileStruct = t.struct({
    infosIndividual: t.maybe(InfoIndividualStruct),
    infosBusiness: t.maybe(InfoBusinessStruct),
    address: AddressStruct,
    owner: t.maybe(OwnerStruct)
  })

  const deleteSubmit = () => {
    setShowMFA(true)
  }

  const deleteMFA = () => {
    setShowDisclaimer(null)
    dispatch(removeSeller(seller.id))
  }

  const editSubmit = () => {
    setShowDisclaimer(null)

    const isValid = t.validate(values, profileStruct).isValid()
    if (!isValid) {
      setShowValidation(true)
    } else {
      setShowValidation(false)

      const address = {
        ...values.address,
        state: values.address.state.value,
        postal_code: values.address.postalCode,
        country_code: values.address.countryCode
      }

      delete address.countryCode
      delete address.postalCode

      let data = {
        ...seller
      }

      if (seller.type === 'individual') {
        data = {
          ...data,
          ...values.infosIndividual,
          mcc: seller.mcc
        }

        data.address = {
          ...address
        }

        delete data.infosIndividual
      } else {
        data = {
          ...data,
          ...values.infosBusiness,
          mcc: seller.mcc
        }

        data.business_address = {
          ...address
        }

        data.owner = {
          ...seller.owner,
          first_name: values.owner.firstName,
          last_name: values.owner.lastName,
          phone_number: values.owner.phone,
          birthdate: moment(values.owner.birthdate).format('YYYY-MM-DD'),
          email: values.owner?.email
        }

        delete data.address
        delete data.owner.firstName
        delete data.owner.lastName
        delete data.owner.phone
        delete data.infosBusiness
      }

      dispatch(updateBusiness(seller.type, seller.id, data, true))
      setHasSavedChangesInLeaveRoute(true)
    }
  }

  useEffect(() => {
    dispatch(getCategoryCodes())
  }, [])

  useEffect(() => {
    if (sellerContext && sellerDetailSuccess) {
      setEditMode(false)
      setShowDisclaimer(null)
    }
  }, [sellerContext, sellerDetailSuccess])

  useEffect(() => {
    if (deleteSellerSuccess) {
      history.push('/')
    }
  }, [deleteSellerSuccess])

  useEffect(() => {
    if (seller && mccData) {
      const mcc = mccData.find((mcc) => mcc.id === seller.mcc)
      const address = seller.address || seller.business_address
      const owner = seller.owner
      let values = {
        address: address && {
          postalCode: address.postal_code || '',
          line1: address.line1 || '',
          line2: address.line2 || '',
          line3: address.line3 || '',
          neighborhood: address.neighborhood || '',
          city: address.city || '',
          state:
            (address.state && {
              label: address.state,
              value: address.state
            }) ||
            '',
          countryCode: 'BR'
        },
        owner: owner && {
          document: owner.taxpayer_id || '',
          birthdate: moment(owner.birthdate).toDate() || '',
          email: owner.email || '',
          firstName: owner.first_name || '',
          lastName: owner.last_name || '',
          phone: owner.phone_number || ''
        },
        additionalInfo: {
          sellerId: seller.id || '',
          referenceId: `${seller.merchant_code || ''} | ${seller.terminal_code || ''}`
        }
      }

      if (seller.type === 'individual') {
        values.infosIndividual = {
          taxpayer_id: seller.taxpayer_id || '',
          birthdate:
            seller.birthdate && seller.birthdate !== '0000-00-00'
              ? moment(seller.birthdate).format('DD/MM/YYYY')
              : '',
          phone_number: seller.phone_number || '',
          first_name: seller.first_name || '',
          last_name: seller.last_name || '',
          description: seller.description || '',
          mcc: mcc && `${mcc.category} / ${mcc.description}`,
          email: seller.email || '',
          statement_descriptor: seller.statement_descriptor || '',
          website: seller.website || '',
          created_at: moment(seller.created_at).format('DD/MM/YYYY') || '',
          revenue: seller.revenue || ''
        }
      } else {
        values.infosBusiness = {
          ein: seller.ein || '',
          business_name: seller.business_name || '',
          statement_descriptor: seller.statement_descriptor || '',
          business_phone: seller.business_phone || '',
          business_description: seller.business_description || '',
          mcc: mcc && `${mcc.category} / ${mcc.description}`,
          business_email: seller.business_email || '',
          business_website: seller.business_website || '',
          created_at: moment(seller.created_at).format('DD/MM/YYYY') || '',
          revenue: seller.revenue || ''
        }
      }

      setInitialValues(values)
      setValues(values)
    }
  }, [seller, mccData])

  const disclaimerOptions = {
    delete: {
      message: (
        <>
          Você está prestes a <strong>excluir</strong> este estabelecimento.
          Deseja prosseguir?
        </>
      ),
      actions: {
        confirm: deleteSubmit,
        confirmLabel: 'Excluir',
        cancel: () => setShowDisclaimer(null)
      }
    },
    edit: {
      message: (
        <>
          Você alterou os <strong>dados cadastrais</strong>. Deseja salvar as
          alterações?
        </>
      ),
      actions: {
        confirm: () => {
          setShowMFA(true)
        },
        confirmLabel: 'Salvar',
        cancel: () => {
          setValues(initialValues)
          setShowDisclaimer(null)
          handleEditMode()
        }
      }
    }
  }

  const handleOnChange = (e) => {
    setValues({
      ...values,
      ...e
    })
  }

  const handleEditMode = () => {
    setEditMode(!editMode)
  }

  const handleCancel = () => {
    setEditMode(false)
    setShowValidation(false)
    setValues(initialValues)
  }

  const handleRouteLeave = () => {
    if (isEqual(initialValues, values) || hasSavedChangesInLeaveRoute) {
      setShowDisclaimer(null)
      return true
    }
    setShowDisclaimer('edit')
    return false
  }

  return (
    <Fragment>
      {showMFA && (
        <MFASingleComponent
          showComponent={setShowMFA}
          onSubmit={showDisclaimer === 'delete' ? deleteMFA : editSubmit}
        />
      )}

      <Prompt message={handleRouteLeave} />
      <GridWrapper noPadding>
        <Can do='update' on='Seller'>
          {seller && !editMode && (
            <EditButton
              ghost
              outline
              data-tracking={`SellerDetail > Info > Edit`}
              onClick={handleEditMode}
              icon='/assets/icons/actions/edit.svg'
              widthAuto
              data-test='seller-detail-profile-edit-btn'
            >
              Editar
            </EditButton>
          )}
          <Grid.Row />
        </Can>
        <Grid.Row auto>
          <LineCard
            backgroundColor={lightestGray}
            label='Dados do Estabelecimento'
            isLoading={!values}
            loadingForm
          >
            <br />
            <br />
            {seller &&
              (seller.type === 'individual' ? (
                <SellerInfoIndividual
                  onChange={handleOnChange}
                  values={values && values.infosIndividual}
                  editMode={editMode}
                  showValidation={showValidation}
                />
              ) : (
                <SellerInfoBusiness
                  onChange={handleOnChange}
                  values={values && values.infosBusiness}
                  editMode={editMode}
                  showValidation={showValidation}
                />
              ))}
            <br />
          </LineCard>
        </Grid.Row>
        <Grid.Row auto>
          <LineCard
            backgroundColor={lightestGray}
            label='Endereço do Estabelecimento'
            isLoading={!values}
            loadingForm
          >
            <br />
            <br />
            <SellerAddress
              onChange={handleOnChange}
              values={values && values.address}
              editMode={editMode}
              isLoading={!values}
              showValidation={showValidation}
            />
            <br />
          </LineCard>
        </Grid.Row>
        {seller && seller.owner && (
          <Grid.Row auto>
            <LineCard
              backgroundColor={lightestGray}
              label='Dados do Proprietário'
              isLoading={!values}
              loadingForm
            >
              <br />
              <br />
              <SellerOwnerData
                onChange={handleOnChange}
                values={values && values.owner}
                editMode={editMode}
                isLoading={!values}
                showValidation={showValidation}
              />
              <br />
            </LineCard>
          </Grid.Row>
        )}
        <Grid.Row auto>
          <LineCard
            backgroundColor={lightestGray}
            label='Informações Adicionais'
            isLoading={!values}
            loadingForm
          >
            <br />
            <br />
            <SellerAdditionalInfoComponent
              values={values && values.additionalInfo}
              isLoading={!values}
            />
            <br />
          </LineCard>
        </Grid.Row>
        <Grid.Row noMargin>
          {showDisclaimer && (
            <Grid.Col cols={16}>
              <Disclaimer
                handleSubmit={disclaimerOptions[showDisclaimer].actions.confirm}
                handleCancel={disclaimerOptions[showDisclaimer].actions.cancel}
                labelCancel='Descartar'
                labelSave={
                  disclaimerOptions[showDisclaimer].actions.confirmLabel
                }
                isLoading={deleteSellerRequest}
                position={'bottom'}
              >
                {disclaimerOptions[showDisclaimer].message}
              </Disclaimer>
            </Grid.Col>
          )}
          <Can do='delete' on='Seller'>
            {seller && !editMode && !showDisclaimer && (
              <Grid.Col cols={3}>
                <Button
                  data-tracking={`SellerDetail > Info > Remove`}
                  data-test='seller-detail-profile-delete-btn'
                  inversed
                  onClick={() => setShowDisclaimer('delete')}
                >
                  Excluir Estabelecimento
                </Button>
              </Grid.Col>
            )}
          </Can>
          {seller && editMode && !showDisclaimer && (
            <Fragment>
              <Button
                data-tracking={`SellerDetail > Info > Cancel`}
                data-test='seller-detail-profile-cancel-btn'
                onClick={handleCancel}
                inversed
              >
                Cancelar
              </Button>
              <Button
                data-tracking={`SellerDetail > Info > Save`}
                data-test='seller-detail-profile-save-btn'
                isLoading={isSellerDetailsRequesting}
                onClick={() => setShowMFA(true)}
              >
                Salvar Alterações
              </Button>
            </Fragment>
          )}
        </Grid.Row>
        <Grid.Row />
        <Grid.Row />
      </GridWrapper>
    </Fragment>
  )
}

ProfileSeller.propTypes = {
  seller: PropTypes.object
}

export default ProfileSeller

const GridWrapper = styled(Grid)`
  position: relative;
  padding-bottom: 4rem;
`

const EditButton = styled(Button)`
  position: absolute;
  top: -25px;
  right: 0;
`
