import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import FormZ from 'src/UIComponents/FormZ/FormZ'
import formValidators from 'src/Utils/formValidators'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import SellerInfoIndividualTemplate from '../../UIComponents/TemplatesForm/SellerInfoIndividualTemplate'
import NumberFactory from '../FormFactories/NumberFactory'
import DocumentFactory from 'src/Components/FormFactories/DocumentFactory'
import PhoneFactory from 'src/Components/FormFactories/PhoneFactory'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import MoneyFactory from 'src/Components/FormFactories/MoneyFactory'

export const struct = t.struct({
  taxpayer_id: t.maybe(t.String),
  birthdate: t.maybe(t.String),
  phone_number: formValidators.Phone,
  first_name: t.maybe(t.String),
  last_name: t.maybe(t.String),
  email: t.maybe(t.String),
  description: formValidators.String,
  mcc: t.maybe(t.String),
  statement_descriptor: formValidators.String,
  website: t.maybe(t.String),
  created_at: t.maybe(t.String),
  revenue: t.maybe(t.String)
})

const SellerInfoIndividual = ({
  values,
  onChange,
  showValidation,
  editMode
}) => {
  const [showFormValidation, setShowFormValidation] = useState(false)

  useEffect(() => {
    setShowFormValidation(showValidation)
  }, [showValidation])

  const handleOnChange = (e) => {
    onChange({
      infosIndividual: {
        ...e
      }
    })
  }

  const formOptions = {
    values: values,
    options: {
      template: (locals) => SellerInfoIndividualTemplate(locals),
      fields: {
        taxpayer_id: {
          label: 'CPF',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'taxpayer_id',
            documentType: 'taxpayer_id',
            disabled: true,
            lock: true
          }
        },
        birthdate: {
          label: 'Data de Nascimento',
          factory: NumberFactory,
          autocomplete: false,
          config: {
            id: 'birthdate',
            disabled: true,
            lock: true
          }
        },
        phone_number: {
          label: 'Telefone',
          factory: PhoneFactory,
          autocomplete: false,
          config: {
            id: 'phone_number',
            disabled: !editMode
          }
        },
        first_name: {
          label: 'Nome',
          factory: InputDefaultFactory,
          config: {
            id: 'first_name',
            disabled: true,
            lock: true
          }
        },
        last_name: {
          label: 'Sobrenome',
          factory: InputDefaultFactory,
          config: {
            id: 'last_name',
            disabled: true,
            lock: true
          }
        },
        description: {
          label: 'Descrição',
          factory: InputDefaultFactory,
          config: {
            id: 'description',
            disabled: !editMode
          }
        },
        mcc: {
          label: 'Categoria do Negócio',
          factory: InputDefaultFactory,
          config: {
            id: 'mcc',
            disabled: true,
            lock: true
          }
        },
        statement_descriptor: {
          label: 'Nome Fantasia (usado na fatura do cartão)',
          factory: InputDefaultFactory,
          config: {
            id: 'statement_descriptor',
            disabled: !editMode
          }
        },
        website: {
          label: 'Site (opcional)',
          factory: InputDefaultFactory,
          config: {
            id: 'website',
            disabled: !editMode
          }
        },
        email: {
          label: 'E-mail',
          factory: InputDefaultFactory,
          config: {
            id: 'email',
            disabled: true,
            lock: true
          }
        },
        created_at: {
          label: 'Data de Criação',
          factory: InputDefaultFactory,
          config: {
            id: 'created_at',
            disabled: true,
            lock: true
          }
        },
        revenue: {
          label: 'Renda',
          factory: MoneyFactory,
          config: {
            id: 'revenue',
            disabled: true,
            lock: true
          }
        }
      }
    }
  }

  return (
    <FormZ
      options={showFormValidation
        ? getFormOptionsWithValidation(formOptions.options, formOptions.values, struct)
        : formOptions.options}
      struct={struct}
      values={formOptions.values}
      onChange={handleOnChange}
      refs='infoForm'
      disabledForm={!editMode}
    />
  )
}

SellerInfoIndividual.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  editMode: PropTypes.bool
}

export { SellerInfoIndividual }
