import React from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import FormZ from 'src/UIComponents/FormZ/FormZ'
import formValidators from 'src/Utils/formValidators'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import DocumentFactory from 'src/Components/FormFactories/DocumentFactory'
import DatePickerFactory from 'src/Components/FormFactories/DatePickerFactory'
import moment from 'moment'
import PhoneFactory from 'src/Components/FormFactories/PhoneFactory'
import SellerOwnerDataTemplate from 'src/UIComponents/TemplatesForm/SellerOwnerDataTemplate'

export const struct = t.struct({
  document: formValidators.Document,
  birthdate: formValidators.BirthDate,
  email: formValidators.Email,
  firstName: formValidators.Name,
  lastName: formValidators.Name,
  phone: formValidators.Phone
})

const SellerOwnerData = ({
  values,
  onChange,
  showValidation,
  editMode
}) => {
  const handleOnChange = (e) => {
    onChange({
      owner: {
        ...e
      }
    })
  }

  const formOptions = {
    values: values,
    options: {
      template: (locals) => SellerOwnerDataTemplate(locals),
      fields: {
        document: {
          label: 'CPF',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'taxpayer-id',
            documentType: 'taxpayer_id',
            disabled: true,
            lock: true
          }
        },
        birthdate: {
          label: 'Data de Nascimento',
          factory: DatePickerFactory,
          autocomplete: false,
          config: {
            id: 'birthdate',
            placeholder: ' ',
            maxDate: moment().subtract(18, 'years').toDate(),
            disabled: !editMode,
            disablePicker: true
          }
        },
        email: {
          label: 'Email do Proprietário',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'email-owner',
            placeholder: ' ',
            disabled: !editMode
          }
        },
        firstName: {
          label: 'Nome',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'first-name',
            placeholder: ' ',
            disabled: !editMode
          }
        },
        lastName: {
          label: 'Sobrenome',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'last-name',
            placeholder: ' ',
            disabled: !editMode
          }
        },
        phone: {
          label: 'Telefone do Proprietário',
          factory: PhoneFactory,
          autocomplete: false,
          config: {
            id: 'owner-phone-number',
            placeholder: ' ',
            disabled: !editMode
          }
        }
      }
    }
  }

  return (
    <FormZ
      options={showValidation
        ? getFormOptionsWithValidation(formOptions.options, formOptions.values, struct)
        : formOptions.options}
      struct={struct}
      values={formOptions.values}
      onChange={handleOnChange}
      refs='OwnerDataForm'
      disabledForm={!editMode}
    />
  )
}

SellerOwnerData.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  editMode: PropTypes.bool
}

export { SellerOwnerData }
