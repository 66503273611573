import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getSellerDocument } from 'src/Actions/seller'

import { DocumentCard } from 'src/Components/DocumentCard'
import EmptyStateDocument from 'src/UIComponents/EmptyState/EmptyStateDocument'

const DocumentsSeller = () => {
  const {
    id: sellerId,
    documents
  } = useSelector(({ context, seller }) => {
    return {
      id: context.seller?.id,
      documents: seller.documents
    }
  })

  const dispatch = useDispatch()

  const [showEmptyState, setShowEmptyState] = useState(true)

  useEffect(() => {
    dispatch(getSellerDocument(sellerId))
  }, [])

  if (documents.length === 0 && showEmptyState) {
    return (
      <EmptyStateDocument
        action={() => {
          setShowEmptyState(false)
        }}
      />
    )
  } else {
    return (
      <Container>
        <DocumentCard
          data-tracking={`Estabelecimentos > Detalhe > Documentos > Enviar comprovante de identidade`}
          data-test='seller-detail-document-send-rg'
          header={<span>Comprovante <strong>de Identidade</strong></span>}
          description='Documento original com foto, válido em todo território nacional. No caso de pessoa jurídica, enviar o Cartão CNPJ.'
          files={documents.filter(({ category }) => category === 'identificacao')}
          category='identificacao'
        />

        <DocumentCard
          data-tracking={`Estabelecimentos > Detalhe > Documentos > Enviar comprovante de residencia`}
          data-test='seller-detail-document-send-residence'
          header={<span>Comprovante <strong>de Residência</strong></span>}
          description='Conta de luz, telefone ou gás do estabelecimento ou do sócio proprietário.'
          files={documents.filter(({ category }) => category === 'residencia')}
          category='residencia'
        />

        <DocumentCard
          data-tracking={`Estabelecimentos > Detalhe > Documentos > Enviar comprovante de atividade`}
          data-test='seller-detail-document-send-mcc'
          header={<span>Comprovante <strong>de Atividade</strong></span>}
          description='Alvará, nota fiscal de compras de produtos, fotos do estabelecimento, cartão de visitas, etc.'
          files={documents.filter(({ category }) => category === 'atividade')}
          category='atividade'
        />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3rem;

  > div:nth-child(2) {
    margin: 0 2.8rem;
  }
`

export default DocumentsSeller
