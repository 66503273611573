import React from 'react'
import * as PropTypes from 'prop-types'

import Grid from 'src/UIComponents/Grid/Grid'
import SaleResume from 'src/UIComponents/SaleDetail/Detail/SaleResume'
import SaleInfoCard from 'src/Components/SaleDetail/SaleInfoCard'
import SaleInfoBoleto from 'src/UIComponents/SaleDetail/Detail/SaleInfoBoleto'
import SaleInfoWallet from 'src/UIComponents/SaleDetail/Detail/SaleInfoWallet'
import SaleInfoPix from 'src/UIComponents/SaleDetail/Detail/SaleInfoPix'
import SaleInfoSeller from 'src/UIComponents/SaleDetail/Detail/SaleInfoSeller'

import {
  isPaymentTypeCard,
  isPaymentTypeBoleto,
  isPaymentTypePix,
  isPaymentTypeWallet
} from 'src/Utils/saleUtils'
import ResumeLoader from 'src/Components/SaleDetail/ResumeLoader'
import SaleInfoBuyer from '../../UIComponents/SaleDetail/Detail/SaleInfoBuyer'
import { isPaymentTypeNuPay } from '../../Utils/saleUtils'
import SaleInfoNupay from '../../UIComponents/SaleDetail/Detail/SaleInfoNupay'

const Detail = ({ sale, buyer, requesting }) => {
  return (
    <Grid noPadding>
      <Grid.Row>
        <Grid.Col cols={14} offset={1}>
          {requesting && <ResumeLoader />}
          {!requesting && sale && <SaleResume sale={sale} />}
        </Grid.Col>
      </Grid.Row>
      {sale && !requesting && (
        <Grid.Row>
          <Grid.Col cols={14} offset={1}>
            {isPaymentTypeCard(sale) && <SaleInfoCard sale={sale} />}
            {isPaymentTypeBoleto(sale) && <SaleInfoBoleto sale={sale} />}
            {isPaymentTypeWallet(sale) && <SaleInfoWallet sale={sale} />}
            {isPaymentTypePix(sale) && <SaleInfoPix sale={sale} />}
            {isPaymentTypeNuPay(sale) && <SaleInfoNupay sale={sale} />}
            <SaleInfoSeller sale={sale} />
            {buyer && <SaleInfoBuyer buyer={buyer} />}
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid>
  )
}

Detail.propTypes = {
  sale: PropTypes.object,
  buyer: PropTypes.object,
  requesting: PropTypes.bool
}

export default Detail
