import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import onClickOutside from 'react-onclickoutside'

import SelectMerchantCode from '../../UIComponents/SelectMerchantCode/SelectMerchantCode'

const SelectMerchantCodeComponent = ({
  label,
  hasError,
  errorMessage,
  // eslint-disable-next-line no-unused-vars
  value,
  onChange
}) => {
  const [open, setOpen] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [search, setSearch] = useState()
  const [listItems, setListItems] = useState()
  const [categoriesState, setCategoriesState] = useState({})

  SelectMerchantCodeComponent.handleClickOutside = () => {
    setOpen(false)
    setSearch(null)
    setCategoriesState({})
  }

  const { merchantCodes } = useSelector(({ merchantCategoryCodes }) => ({
    merchantCodes: merchantCategoryCodes.mccData
  }))

  useEffect(() => {
    const mccs = getMerchantCategoriesCodes(merchantCodes)

    const listsCategories = Object.entries(mccs).reduce((acc, category) => {
      return [
        ...acc,
        {
          label: category[0],
          options: category[1]
        }
      ]
    }, [])
    setListItems(listsCategories)
  }, [merchantCodes])

  const handleSelect = () => {
    setOpen(!open)
    setSearch(null)
  }

  const handleCategoryClick = (categoryLabel) => {
    setCategoriesState({ ...categoriesState, [categoryLabel]: !categoriesState[categoryLabel] })
  }

  const getFilteredOptions = () => {
    if (!search) return listItems

    return listItems.reduce((acc, currentCategory) => {
      const { options, label } = currentCategory
      const matchSearch = search ? search.toLowerCase() : ''
      const matchCategory = label.toLowerCase().includes(matchSearch)
      const matchOptions = options.filter((option) => option && option.description && option.description.toLowerCase().includes(matchSearch))

      if (matchCategory) {
        return [...acc, currentCategory]
      } else if (matchOptions && matchOptions.length > 0) {
        return [
          ...acc,
          {
            label,
            options: matchOptions
          }
        ]
      } else return acc
    }, [])
  }

  const getMerchantCategoriesCodes = (list) => {
    if (list) {
      const mcc = list.reduce((acc, current) => {
        acc[current.category || 'Sem categoria'] = acc[current.category || 'Sem categoria'] || []
        acc[current.category || 'Sem categoria'].push({
          description: current.description,
          code: current.code,
          id: current.id
        })
        return acc
      }, {})
      return mcc
    }
    return {}
  }

  const selectedItem = (e, item) => {
    e.preventDefault()
    setSelectedOption(item)
    onChange(`${item.id}:${item.code}`)
    setSearch(null)
    setOpen(false)
  }

  const itemsFiltered = getFilteredOptions()

  return (
    <SelectMerchantCode
      value={selectedOption ? selectedOption.description : ''}
      label={label}
      open={open}
      onClick={handleSelect}
      onSelect={selectedItem}
      onSearchChange={(value) => setSearch(value)}
      items={itemsFiltered}
      selected={selectedOption ? selectedOption.id : ''}
      hasError={hasError}
      errorMessage={errorMessage}
      categoriesState={categoriesState}
      collapseControl={handleCategoryClick}
      search={search}
    />
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => SelectMerchantCodeComponent.handleClickOutside
}

SelectMerchantCodeComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string
}

export default onClickOutside(SelectMerchantCodeComponent, clickOutsideConfig)
