import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import t from 'tcomb-form'

import ReactSVG from 'react-svg'
import {
  captureTransaction,
  getTransactionCancelledLetter,
  voidTransaction
} from 'src/Actions/transactions'
import MoneyFactory from 'src/Components/FormFactories/MoneyFactory'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Card from 'src/UIComponents/Card/Card'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import formValidators from 'src/Utils/formValidators'
import {
  isAbleToAuthorize,
  isAbleToCancel,
  isAbleToPartialCancel,
  isEligibleToCancelledLetter
} from 'src/Utils/saleUtils'
import { cancelBoletoTransaction } from '../../Actions/transactions'
import RadioGroupFactory from '../../Components/FormFactories/RadioGroupFactory'
import Can from '../../Helpers/Permission/Can'
import { darkGray } from '../../Styles/settings/Constants'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'
import { getFormOptionsWithValidation } from '../../Utils/FormUtils'
import {
  isAbleToCancelBoleto,
  isAbleToCancelByUser
} from '../../Utils/saleUtils'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const SaleActions = ({ sale, isBoleto = false }) => {
  const dispatch = useDispatch()
  const [showValidation, setShowValidation] = useState(false)
  const [isRefundOpen, setIsRefundOpen] = useState(false)
  const [showMFA, setShowMFA] = useState(false)
  const [formValues, setFormValues] = useState({
    method: isBoleto
      ? 'cancelBoleto'
      : isAbleToAuthorize(sale)
        ? 'preAuthorization'
        : 'refund',
    amount: sale.amount
  })
  const [formRadioGroupOptions, setFormRadioGroupOptions] = useState([])

  const handleCancelBoletoTransaction = () => {
    dispatch(cancelBoletoTransaction(sale.id))
  }

  const handleOpenMFA = () => {
    const isValid = t.validate(formValues, struct).isValid()
    if (!isValid) {
      setShowValidation(true)
      return
    }

    setShowMFA(true)
  }

  const handleAuthorizeTransaction = () => {
    dispatch(
      captureTransaction(sale.id, {
        amount: Math.round(formValues.amount * 100),
        on_behalf_of: sale.on_behalf_of
      })
    )
  }

  const handleVoidTransaction = () => {
    dispatch(
      voidTransaction(sale.id, {
        amount: Math.round(formValues.amount * 100),
        on_behalf_of: sale.on_behalf_of
      })
    )
  }

  const handleSubmit = () => {
    const isValid = t.validate(formValues, struct).isValid()
    if (!isValid) {
      setShowValidation(true)
      return
    }

    types[formValues.method].onSubmit()
  }

  const types = {
    refund: {
      title: 'Estornar Venda',
      label: 'Valor do estorno',
      warning: 'Atenção! A venda só pode ser estornada uma vez.',
      button: 'Estornar Venda',
      onSubmit: () => handleVoidTransaction()
    },
    preAuthorization: {
      title: 'Autorizar Venda',
      label: 'Valor da autorização',
      warning: 'Atenção! A venda só pode ser autorizada uma vez.',
      button: 'Autorizar Venda',
      onSubmit: () => handleAuthorizeTransaction()
    },
    cancel: {
      title: 'Cancelar Venda',
      label: 'Valor do cancelamento',
      warning: 'Atenção! Essa é uma ação definitiva.',
      button: 'Cancelar Venda',
      onSubmit: () => handleVoidTransaction()
    },
    cancelBoleto: {
      title: 'Cancelar Venda',
      label: 'Valor do cancelamento',
      warning: 'Atenção! Essa é uma ação definitiva.',
      button: 'Cancelar Venda',
      onSubmit: () => handleCancelBoletoTransaction()
    }
  }

  useEffect(() => {
    setFormRadioGroupOptions(getOptions())
  }, [sale])

  const {
    voidTransactionRequesting,
    cancelBoletoTransactionRequesting,
    captureTransactionRequesting,
    cancelBoletoTransactionError,
    voidTransactionError,
    captureTransactionError,
    getTransactionCancelledLetterRequesting,
    listOfAllowedUsersToReverseNubankTransactions,
    userId
  } = useSelector(({ transactions, settings, user }) => ({
    voidTransactionRequesting: transactions.voidTransactionRequesting,
    cancelBoletoTransactionRequesting:
      transactions.cancelBoletoTransactionRequesting,
    captureTransactionRequesting: transactions.captureTransactionRequesting,
    cancelBoletoTransactionError: transactions.cancelBoletoTransactionError,
    voidTransactionError: transactions.voidTransactionError,
    captureTransactionError: transactions.captureTransactionError,
    getTransactionCancelledLetterRequesting:
      transactions.getTransactionCancelledLetterRequesting,
    listOfAllowedUsersToReverseNubankTransactions:
      settings.items.usersEnabledToReverseInstantTransactions,
    userId: user.id
  }))

  const handleOnChange = (e) => {
    const values = {
      ...e,
      amount: e.method !== formValues.method ? sale.amount : e.amount
    }
    setFormValues(values)
  }

  const getOptions = () => {
    let options = []

    if (isAbleToAuthorize(sale)) {
      options.push({
        value: 'preAuthorization',
        label: types.preAuthorization.title
      })
    }

    if (isAbleToCancelBoleto(sale)) {
      options.push({
        value: 'cancelBoleto',
        label: types.cancelBoleto.title
      })
    }

    if (isAbleToAuthorize(sale) && isAbleToCancel(sale)) {
      options.push({
        value: 'cancel',
        label: types.cancel.title
      })
    }

    if (
      !isAbleToAuthorize(sale) &&
      (isAbleToCancel(sale) || isAbleToPartialCancel(sale))
    ) {
      options.push({
        value: 'refund',
        label: types.refund.title
      })
    }

    return options
  }

  const formOptions = {
    values: formValues,
    options: {
      fields: {
        method: {
          label: '',
          factory: RadioGroupFactory,
          autocomplete: false,
          config: {
            options: formRadioGroupOptions
          }
        },
        amount: {
          label: types[formValues.method].label,
          factory: MoneyFactory,
          autocomplete: false,
          config: {
            id: 'amount',
            maxValue: sale.amount,
            disabled: !isAbleToPartialCancel(sale)
          }
        }
      }
    }
  }

  const struct = t.struct({
    method: formValidators.String,
    amount: formValidators.MaxValue(sale.amount)
  })

  const error =
    cancelBoletoTransactionError ||
    voidTransactionError ||
    captureTransactionError

  return (
    <>
      {showMFA && (
        <MFASingleComponent
          isSidebar
          showComponent={setShowMFA}
          onSubmit={handleSubmit}
        />
      )}
      <Can do='create' on='Transaction' passThrough>
        {(canMakeTransactionActions) => (
          <Grid.Col cols={16}>
            <Can I='create' a='CancelledLetter'>
              <Grid.Row auto>
                {isEligibleToCancelledLetter(sale.history) && (
                  <Button
                    data-tracking={`Solicitar Carta de Cancelamento`}
                    data-test='transaction-detail-cancelledLetter-btn'
                    isLoading={getTransactionCancelledLetterRequesting}
                    onClick={() => {
                      dispatch(getTransactionCancelledLetter(sale.id))
                    }}
                  >
                    Emitir Carta de Cancelamento
                  </Button>
                )}
              </Grid.Row>
            </Can>
            {(isAbleToCancel(sale) ||
              isAbleToAuthorize(sale) ||
              isAbleToPartialCancel(sale) ||
              isAbleToCancelBoleto(sale)) &&
              isAbleToCancelByUser(
                listOfAllowedUsersToReverseNubankTransactions,
                userId
              ) &&
              canMakeTransactionActions && (
                <Grid.Row
                  auto
                  data-tracking={`Vendas > Detalhe > Forumulario > ${types[formValues.method].button}`}
                >
                  {!isAbleToAuthorize(sale) &&
                  (isAbleToCancel(sale) || isAbleToCancelBoleto(sale)) &&
                  !isRefundOpen ? (
                    <Button
                      data-tracking={`Vendas > Detalhe > Estornar`}
                      data-test='transaction-detail-estorno-btn'
                      onClick={() => setIsRefundOpen(true)}
                      inversed
                    >
                      {isBoleto ? 'Cancelar Venda' : 'Estornar Venda'}
                    </Button>
                  ) : (
                    <Card
                      handleClose={() => isRefundOpen && setIsRefundOpen(false)}
                      buttonClose={isRefundOpen}
                      padding={'5px 20px 20px 20px'}
                    >
                      <FormZ
                        data-tracking={`Vendas > Detalhe > Formulario > ${types[formValues.method].button}`}
                        options={
                          showValidation
                            ? getFormOptionsWithValidation(
                                formOptions.options,
                                formOptions.values,
                                struct
                              )
                            : formOptions.options
                        }
                        struct={struct}
                        values={formOptions.values}
                        onChange={handleOnChange}
                      />
                      {error && (
                        <AlertMessage
                          type={error.type || 'error'}
                          error={error.type !== 'info' ? error : null}
                          info={error.type === 'info' ? error : null}
                        />
                      )}
                      <Grid.Row />
                      <WarningContainer color={darkGray}>
                        <IconWarning src='/assets/icons/alerts/alert.svg' />
                        {types[formValues.method].warning}
                      </WarningContainer>
                      <Button
                        onClick={handleOpenMFA}
                        isLoading={
                          cancelBoletoTransactionRequesting ||
                          voidTransactionRequesting ||
                          captureTransactionRequesting
                        }
                        data-tracking={`Vendas > Detalhe > Estornar > Enviar > ${types[formValues.method].button}${error ? ' > Tentar novamente' : ''}`}
                        data-test='transaction-detail-submit-btn'
                        type='submit'
                      >
                        {types[formValues.method].button}
                      </Button>
                    </Card>
                  )}
                </Grid.Row>
              )}
          </Grid.Col>
        )}
      </Can>
    </>
  )
}

SaleActions.propTypes = {
  sale: PropTypes.object,
  isBoleto: PropTypes.bool
}

export { SaleActions }

const WarningContainer = styled(Label)`
  display: flex;
  margin-bottom: 5px;
`

const IconWarning = styled(ReactSVG)`
  margin-right: 5px;
  margin-top: -1px;
`
