import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import t from 'tcomb-form'
import { useDispatch, useSelector } from 'react-redux'

import formValidators from 'src/Utils/formValidators'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import Grid from 'src/UIComponents/Grid/Grid'
import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'
import { darkGray, normalText } from 'src/Styles/settings/Constants'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import styled from 'styled-components'
import { sendReceipt, resetSendReceipt } from 'src/Actions/receipt'
import SuccessCard from 'src/UIComponents/SuccessCard/SuccessCard'
import { SendReceiptMessageSuccess } from 'src/UIComponents/SuccessMessages/SuccessMessages'

const SendReceipt = ({ receiptId }) => {
  const dispatch = useDispatch()

  const {
    sendReceiptEmail,
    sendReceiptRequesting,
    sendReceiptError
  } = useSelector(({ receipt }) => ({
    sendReceiptRequesting: receipt.sendReceiptRequesting,
    sendReceiptError: receipt.sendReceiptError,
    sendReceiptEmail: receipt.sendReceiptEmail
  }))

  const [email, setEmail] = useState('')
  const [ showValidation, setShowValidation ] = useState(false)

  const formOptions = {
    values: {
      email
    },
    options: {
      fields: {
        email: {
          label: 'E-mail',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'email'
          }
        }
      }
    }
  }

  const struct = t.struct({
    email: formValidators.Email
  })

  const onChange = values => {
    setEmail(values.email)
  }

  const handleOnClick = () => {
    const isFormValid = t.validate(formOptions.values, struct).isValid()

    if (isFormValid) {
      dispatch(sendReceipt(receiptId, email))
    }
    setShowValidation(true)
  }

  if (sendReceiptEmail) {
    return (
      <SuccessCard sizeButton='196px' noPadding>
        <SendReceiptMessageSuccess
          onClick={() => dispatch(resetSendReceipt())}
          email={sendReceiptEmail}
        />
      </SuccessCard>
    )
  }

  return (
    <Grid noPadding>
      <Grid.Row>
        <Grid.Col offset={1} cols={14}>
          <Card>
            <Grid noPadding>
              <Grid.Row bigMarginBottom auto>
                <StyledLabel
                  fontSize={normalText}
                  color={darkGray}
                  textAlign='left'
                >
                  Enviar <strong>Comprovante</strong>
                </StyledLabel>
              </Grid.Row>
              <Grid.Row auto>
                <FormZ
                  buttonFullWidth
                  options={showValidation
                    ? getFormOptionsWithValidation(formOptions.options, formOptions.values, struct)
                    : formOptions.options
                  }
                  struct={struct}
                  values={formOptions.values}
                  onChange={onChange} />
              </Grid.Row>
              {sendReceiptError && <Grid.Row auto>
                <AlertMessage noMargin type='error' error={sendReceiptError} />
              </Grid.Row>}
              <Grid.Row auto>
                <Button
                  isLoading={sendReceiptRequesting}
                  onClick={handleOnClick}
                  widthAuto
                  data-test='transaction-detailSale-sendReceipt-btn'
                >
                  {sendReceiptError ? 'Tentar Novamente' : 'Enviar Comprovante'}
                </Button>
              </Grid.Row>
            </Grid>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
}

const StyledLabel = styled(Label)`
  display: block;
`

export default SendReceipt

SendReceipt.propTypes = {
  receiptId: PropTypes.string
}
